/**
 * Timetastic
 * How It Works page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SignupButton from "src/components/signupButton"

// Styles
import "src/styles/templates/how-it-works.scss"
import "src/styles/animations/how-it-works.scss"

// SEO
const title = "Employee holiday tracker absence calendar"
const description =
  "Custom leave types, departments, public holidays, work schedule and more - Timetastic Features"

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <section
            className="c-section c-how-screenshots is-unanimated"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-how-screenshots__inner">
              <div>
                <h1 className="h h2 c-how-screenshots__title">
                  Works for you and{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    your team
                  </span>
                </h1>
                <p>
                  Timetastic is more than just a streamlined way to book time
                  off work - it’s a <b>better</b> way of getting to grips with
                  staff absence.
                </p>
                <p>
                  Companies that switch to Timetastic become more transparent,
                  they plan better, work better, and value their team - you’ll
                  see a happier and more productive workforce.
                </p>
                <p>
                  It’s a <b>big step forward</b>, but simple to take.
                </p>

                <SignupButton
                  buttonCopy="Give Timetastic a try"
                  trackCtaLabel="FeaturesHeroSignUp"
                />

                <p className="c-free-trial__no-link">
                  Free for one month - no card required
                </p>
              </div>

              <div className="c-how-screenshots__images is-unanimated">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-how-screenshots__tablet"
                  />
                  <StaticImage
                    src="../images/hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-how-screenshots__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-how-screenshots__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>

          <section className="c-section u-text-centre c-home-video">
            <div className="u-inner u-inner--l u-inner--hero is-unanimated">
              <div className="c-video c-video__explainer">
                <h2 className="h h2 u-text-centre">See Timetastic in action</h2>
                <div className="c-video__player">
                  <iframe
                    title="See Timetastic in action"
                    src="https://player.vimeo.com/video/880192428?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                    allowFullScreen={true}
                    loading="lazy"
                  ></iframe>
                </div>
                <SignupButton
                  buttonCopy="Start free trial"
                  trackCtaLabel="HowItWorksVideoSignup"
                />
                <p className="c-free-trial__no-link">
                  Free for one month - no card required
                </p>
              </div>
            </div>
          </section>

          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className=" is-unanimated">
                <h2 className="h h2 u-text-centre-desktop">
                  Instead of messy paperwork,{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    you get:
                  </span>
                </h2>
              </div>

              <div className="c-features">
                <div className="c-feature c-feature--calendars">
                  <div className="is-unanimated">
                    <div className="c-feature--calendars__blob-01">
                      <BackgroundBlobs blobType="howCalendarBlobs">
                        <div className="c-feature-calendars-grid__item">
                          <StaticImage
                            src="../images/calendars-months.jpg"
                            width={619}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-months-2.jpg"
                            width={627}
                            alt="Calendar months"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-months-2 c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-summary.jpg"
                            width={306}
                            alt="Leave summary"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-home-grid__annotation c-feature-calendars-grid__image-summary c-home-calendars-grid__image"
                          />
                          <StaticImage
                            src="../images/calendars-breakdown.jpg"
                            width={306}
                            alt="Your time off"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-breakdown"
                          />
                          <StaticImage
                            src="../images/calendars-trends.jpg"
                            width={306}
                            alt="Annual trends"
                            placeholder="blurred"
                            layout="constrained"
                            className="c-feature-calendars-grid__image c-feature-calendars-grid__image-trends"
                          />
                        </div>
                      </BackgroundBlobs>
                    </div>
                  </div>
                  <div className=" is-unanimated">
                    <h3 className="h h3">Individual calendars</h3>
                    <p>
                      Every member of staff gets a personal calendar and
                      summary. You can see and track time off, make plans for
                      this year or next.
                    </p>
                    <p>
                      It’s easy to see holidays you've taken, what’s upcoming,
                      and how much allowance you have left for the year.
                    </p>
                    <p>
                      Using the calendar integrations you can sync Timetastic
                      directly into a calendar of your choice; Outlook, Gmail
                      and Apple are all supported.
                    </p>
                  </div>
                </div>

                <div className="c-feature c-feature__notifications">
                  <div className="is-unanimated">
                    <h3 className="h h3">App &amp; email notifications</h3>
                    <p>
                      Rather than shuffling bits of paper around the office and
                      digging through desk drawers for a holiday request form,
                      you open Timetastic, click the dates you want off and the
                      request is sent direct to your manager. All they have to
                      do is click approve or decline in the email.
                    </p>
                  </div>
                  <div className="c-feature-notifications-grid__item">
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={249}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={249}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval"
                    />
                    <StaticImage
                      src="../images/notifications-request.jpg"
                      width={464}
                      alt="Request notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-request-s"
                    />
                    <StaticImage
                      src="../images/notifications-approval.jpg"
                      width={464}
                      alt="Approval notification"
                      placeholder="blurred"
                      layout="constrained"
                      className="c-feature-calendars-grid__image c-feature-calendars-grid__image-approval-s"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="c-section c-features-gradient-blob">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated u-text-center u-text-left-mobile">
                <h2 className="h h1">
                  A live wallchart,
                  <br /> with{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    bells
                  </span>{" "}
                  and{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    whistles
                  </span>
                </h2>
                <p>
                  The first thing you’ll see in Timetastic is your new
                  interactive wallchart. At a glance you can see who’s in and
                  who’s not. Click to request time off, use it to plan ahead,
                  make sure busy periods are covered and avoid any clashes.
                </p>

                <div className="c-home-grid__annotation">
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                    Your team
                  </span>
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                    Book time off
                  </span>
                  <StaticImage
                    src="../images/live-wallchart.jpg"
                    width={1200}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart"
                  />
                  <StaticImage
                    src="../images/live-wallchart-mobile.jpg"
                    width={440}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-calendars-grid__image c-feature-calendars-grid__image-wallchart-s"
                  />
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                    Pending request
                  </span>
                </div>
              </div>
            </div>

            <div className="u-inner u-inner--800 is-unanimated">
              <h2 className="h h2 u-text-center u-text-left-mobile c-feature-points-title">
                A full set of features, so you can{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  manage time off properly
                </span>
              </h2>
            </div>
            <div className="u-inner u-inner--l">
              <div className="c-feature-points">
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">Departments</h3>
                    <p>
                      Group your staff into departments, allocate one boss to
                      manage the whole team, set minimum staffing levels and see
                      clashes before approving requests for time off.
                    </p>
                  </div>
                  <StaticImage
                    src="../images/manage-different-teams.jpg"
                    width={539}
                    alt="Manage different teams"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-point__image"
                  />
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">Leave types</h3>
                    <p>
                      Handle any type of absence - annual leave, sickness,
                      maternity and paternity, unpaid leave, compassionate and
                      meetings.
                    </p>
                  </div>
                  <ul className="u-tag-list">
                    <li className="">Annual Leave</li>
                    <li className="">Sickness</li>
                    <li className="">Maternity/Paternity</li>
                    <li className="">Sabbatical</li>
                    <li className="">Doctors Appointment</li>
                    <li className="">Seasonal Shutdown</li>
                    <li className="">Compassionate</li>
                    <li className="">Away Day</li>
                    <li className="">Training</li>
                  </ul>
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      Public holidays
                    </h3>
                    <p>
                      Public holidays from over 3,000 regions are supported in
                      Timetastic. From UK to the USA, Afghanistan to Zimbabwe,
                      we've got you covered.
                    </p>
                  </div>
                  <p>
                    <img
                      loading="lazy"
                      alt="Public holidays from 250 countries"
                      className="c-features-grid__imagex"
                      src="/images/features/leave-types/public-holidays-map.png"
                      width="539"
                      height="281"
                    />
                  </p>
                </div>
                <div className="c-feature-point is-unanimated">
                  <div className="c-feature-point__body">
                    <h3 className="h h3 c-feature-point__title">
                      Work schedules
                    </h3>
                    <p>
                      Each employee has a work schedule split into two sessions
                      to account for a lunch break. Their working week is
                      reflected on the team calendars so you can see who should
                      be working and when.
                    </p>
                  </div>
                  <StaticImage
                    src="../images/work-schedules.jpg"
                    width={539}
                    alt="Work schedules"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-feature-point__image"
                  />
                </div>
              </div>
            </div>
          </section>

          <FreeTrial
            body="Accountants, garages, marketing agencies, lawyers, engineers - over 7,000 businesses just like yours manage their team’s absence with Timetastic."
            buttonCopy="Give it a try now"
            coloured={false}
            gradientBottom={true}
            title="See how easy it is"
            trackCtaLabel="FeaturesSignUp"
          />

          <section className="c-section  u-text-center u-text-left-mobile c-features-logos">
            <div className="u-inner u-inner--ms is-unanimated">
              <h2 className="h h2">
                Timetastic{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  works with
                </span>{" "}
                your other apps
              </h2>
              <p>
                Timetastic integrates with: Google Calendar, Outlook Calendar, G
                Suite, Apple Calendar, and Slack.
              </p>
            </div>
            <div className="u-inner u-inner--l is-unanimated">
              <div className="c-features-logos__grid">
                <img
                  loading="lazy"
                  alt="Google Calendar"
                  height="85"
                  src="/images/google-calendar-icon.svg"
                  width="85"
                />
                <img
                  loading="lazy"
                  alt="Google Workspace"
                  height="21"
                  src="/images/google-workspace-icon.svg"
                  width="160"
                />
                <img
                  loading="lazy"
                  alt="Apple Calendar"
                  height="85"
                  src="/images/apple-calendar-icon.svg"
                  width="85"
                />
                <img
                  loading="lazy"
                  alt="Slack"
                  height="65"
                  src="/images/slack-logo.svg"
                  width="160"
                />
                <img
                  loading="lazy"
                  alt="Outlook Calendar"
                  height="75"
                  src="/images/outlook-icon.svg"
                  width="85"
                />
              </div>

              <p className="u-text-center u-text-left-mobile is-unanimated">
                <SignupButton
                  buttonCopy="Give Timetastic a try"
                  trackCtaLabel="FeaturesSignUpFooter"
                />
              </p>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
